import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect, jwtAuthenticator, StringCodec } from 'nats.ws';
import { QRCodeCanvas } from 'qrcode.react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { PlayCircle, QrCodeScan, ArrowClockwise } from 'react-bootstrap-icons';


export default function App() {
  const [proofRequestURL, setProofRequestURL] = useState('')
  const [isLoading, setIsloading] = useState(false)
  const [show, setShow] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [os, setOs] = useState('')
  const [deepLinkUrl, setDeepLinkUrl] = useState('')
  

  const natsListener = async (threadId, token) => {

    try {
      //const seed = `SUAOCNCDWVZGDKIT63PAJVGCK5O6GMBMEJG3S52LZZILDNP4LTVPNN5FPE`
       const seed = `SUAOCNCDWVZGDKIT63PAJVGCK5O6GMBMEJG3S52LZZILDNP4LTVPNN5FPE`
      

      const conn = await connect({
       // servers: ["wss://natsg2c.bhutanndi.com"],
        servers: ["wss://natsg2c-client.bhutanndi.com"],
        authenticator: jwtAuthenticator(token, new TextEncoder().encode(seed))

      })

      const sc = StringCodec();
      const s1 = conn.subscribe(threadId);

      for await (const m of s1) {
        const data = JSON.parse(sc.decode(m.data))
        if(data?.data?.type === 'present-proof/presentation-result'){
          if (data?.data?.verification_result === 'ProofValidated') {
            const { "Full Name": fullName, "ID Number": idNumber } = data.data.requested_presentation.revealed_attrs
  
                // Create an object with cid and fullName
            // const jsonData = {
            //   cid: idNumber.value,
            //   fullName: fullName.value
            // }
            const params = {
              cid: idNumber[0].value,
              fullName: fullName[0].value
            }
  
            const headers = {
              "Content-Type": "application/json"
            }
            conn.close();
            // Convert the object to a JSON string and encode it
            //const jsonString = encodeURIComponent(JSON.stringify(jsonData));
  
            if (os === "Other") {
              //window.location.replace(`https://brtp.citizenservices.gov.bt/dofps/ndiLandingPage.html?cid=${idNumber.value}&fullName=${fullName.value}`);
            //  window.location.replace(`https://brtp.citizenservices.gov.bt/dofps/ndiLandingPage.html?data=${jsonString}`);
            axios.post("https://brtp.citizenservices.gov.bt/dofps/ndiLandingPage.html", params, { headers })
              .then((response) => {
                console.log(response.data)
               // window.open(`https://brtp.citizenservices.gov.bt/dofps/redirect.html`, '_self');
                window.location.href =`https://brtp.citizenservices.gov.bt/dofps/redirect.html`;
              }
              ).catch((err) => {
                if (err.response) {
                  const { message } = err.response.data
                  setErrorMessage(message)
                }
              }).finally(() => {
                setTimeout(() => {
                  setIsloading(false)
                }, 1000)
              })
            } else {
              //window.open(`https://brtp.citizenservices.gov.bt/dofps/ndiLandingPage.html?cid=${idNumber.value}&fullName=${fullName.value}`, '_blank');
              //window.open(`https://brtp.citizenservices.gov.bt/dofps/ndiLandingPage.html?data=${jsonString}`, '_blank');
           
              axios.post("https://brtp.citizenservices.gov.bt/dofps/ndiLandingPage.html", params, { headers })
              .then((response) => {
                console.log(response.data)
               // window.open(`https://brtp.citizenservices.gov.bt/dofps/redirect.html`, '_self');
                window.location.href =`https://brtp.citizenservices.gov.bt/dofps/redirect.html`;
              }
              ).catch((err) => {
                if (err.response) {
                  const { message } = err.response.data
                  setErrorMessage(message)
                }
              }).finally(() => {
                setTimeout(() => {
                  setIsloading(false)
                }, 1000)
              })
              
            }
          }
        }
        else if(data?.data?.type === 'present-proof/rejected'){
          setErrorMessage('You have denied proof-request!')
        }
        
      }
    } catch (e) {
      console.log(e)
    }
  }
  
  const sendProofRequest = (token) => {
    //const cred_id = "KqRp5pz9HoFho42Wkvt9XQ:3:CL:613:revocable"
    const schema_name = "https://schema.ngotag.com/schemas/fb675203-b317-4675-a657-be7f5d1d57fb"
    
    if (token !== '') {
      const params = {
        proofName: 'verifyUser',
        proofAttributes: [
          {
            name: "Full Name",
            restrictions: [{
             // 'cred_def_id': cred_id,
              'schema_name': schema_name,
            }]
          },
          {
            name: "ID Number",
            restrictions: [{
              //'cred_def_id': schema_name
              'schema_name': schema_name
            }]
          },
        ]
      }

      const headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": `Bearer ${token}`
      }
      //axios.post("https://servicesg2c.bhutanndi.com/verifier/proof-request", params, { headers })
      axios.post("https://g2c-client.bhutanndi.com/verifier/proof-request", params, { headers })
        .then((response) => {
          const { proofRequestURL, proofRequestThreadId, deepLinkURL } = response.data.data;
          setDeepLinkUrl(deepLinkURL);
          setProofRequestURL(proofRequestURL);
          natsListener(proofRequestThreadId, token)
        }
        ).catch((err) => {
          if (err.response) {
            const { message } = err.response.data
            setErrorMessage(message)
          }
        }).finally(() => {
          setTimeout(() => {
            setIsloading(false)
          }, 1000)
        })
    }
  }

  const handleLoginWithNdi = () => {
    const headers = {
      client_id: "4sfp321ffih5ujf3bmukaseboo",
      client_secret: "1jnpmc2961f2lum5d4ejlbkvqo9d4376j64htr194i6n0obg128e",
      grant_type: "client_credentials"
    }
    setIsloading(true)
    setShow(true)
    setErrorMessage('')
   // axios.post("https://services.bhutanndi.com/authentication/authenticate", headers)
    axios.post("https://core.bhutanndi.com/authentication/authenticate", headers)
      .then((response) => {
        const { access_token } = response.data
        sendProofRequest(access_token);
        setShow(true)
      }).catch((err) => console.error(err))
  }

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    if (/Android/.test(userAgent)) {
      setOs('Android');
    } else if (/iPhone|iPad|iPod/.test(userAgent)) {
      setOs('iOS');
    } else {
      setOs('Other');
    }
    console.log(os, "OS");

  }, [os])

  return (
    <div>
      <header>
        {/* <button className="toggle-button">&#9776;</button> */}
        <span className="header-text">Toll Free Number:1199 / E-mail:g2c@cabinet.govt.bt</span>
      </header>
      <div className='text-center'>
        <img className="mt-3" src={require('./assets/images/logo.png')} alt="" width={100} height={100} />
        <h5>CITIZEN SERVICE PORTAL </h5>
        <h6 style={{ textAlign: "center", color: "#0ba5d9" }}> ROYAL GOVERNMENT OF BHUTAN - In pursuit improving public service delivery </h6>
      </div>
      <hr />
      <div className="container" id="loadMainPage">
        <div className="row justify-content-center mt-5">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <div className="card mb-3">
              <div className="card-body">
                {!show ?
                  <div className="col-lg-12 col-md-12 col-sm-12" id="socialBx">
                    <h6 className="card-title text-center">Login as citizen </h6>
                    {/* <div className="form-group text-center">
                      <a href="https://sis.citizenservices.gov.bt">
                        <button style={{ background: "#ffa500", borderColor: "#ffa500" }} type="submit" className="btn text-white cus-button">Login with SSO </button></a>
                    </div> */}
                    <div className="form-group text-center">
                      <div className="line-container">
                        {/* <div className="line"></div>
                        <span className="text">OR</span> */}
                        <div className="line"></div>
                      </div>
                      <div className="form-group">
                        <button style={{ background: "#124143", borderColor: "#F0F9F4" }} type="submit" className="btn text-white btn-bg cus-button" onClick={() => handleLoginWithNdi()}>
                          <img src={require("./assets/images/UI_NDIlogo.png")} style={{ height: "25px", width: "25px", marginRight: "10px" }} />
                          Login with Bhutan NDI
                        </button>
                      </div>
                       <div className="form-group">
                        <label htmlFor="password" className='mt-5'>Login as executing agency/official </label>
                        <hr/>
                        <div className="form-group text-center">
                          <a href="https://brtp.citizenservices.gov.bt/dofps/loginMain"><button style={{ background: "#0ba5d9", borderColor: "#0ba5d9" }} type="submit" className="btn text-white cus-button">Agency Login </button></a>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <>
                    <div className="col-lg-12 col-md-12 col-sm-12">
                      <div style={{ textAlign: "center" }} className="form-group">
                        <span style={{ fontWeight: "bold" }}>Scan with <span style={{ color: "#5ac994" }}>Bhutan NDI</span> Wallet </span>
                      </div>
                      <div className="form-group text-center mt-4">
                        {isLoading ?
                          <div className='d-flex justify-content-center mt-3'>
                            <div className="spinner-border" role="status">
                              <span className="sr-only">QR</span>
                            </div>
                          </div> :
                          <div className="form-group">
                            {errorMessage !== '' ? <span className='text-danger'> {errorMessage} <br /> <a href='#' onClick={() => handleLoginWithNdi()} style={{ textDecoration: "none" }}> <ArrowClockwise /> Retry</a> </span> : os === 'Other' ?

                              <QRCodeCanvas
                                value={proofRequestURL}
                                imageSettings={{
                                  src: require("./assets/images/NDIlogobg.png"),
                                  excavate: true,
                                  height: 50,
                                  width: 50
                                }}
                                size={260}
                              />
                              :
                              <button style={{ background: "#124143", borderColor: "#097969" }} type="submit" className="btn text-white btn-bg cus-button" onClick={() => window.location.replace(deepLinkUrl)}>
                                <img src={require("./assets/images/UI_NDIlogo.png")} style={{ height: "25px", width: "25px", marginRight: "10px" }} />
                                Click here to Open NDI App
                              </button>
                            }
                          </div>
                        }
                      </div>
                      <div className='form-group mt-4'>
                        <div className='d-flex justify-content-center'>
                          <ol className='list-inline-item'>
                            <li className=''>Open Bhutan NDI App on your phone</li>
                            {/* <li className=''>Tap the Scan button located on the menu bar <QrCodeScan color='white' className='rounded-pill' style={{ backgroundColor: "#5ac994", padding: '2px' }} size={24} /> <br /> and capture QR code</li> */}
                            <li className=''>Tap the Scan button located on the menu bar 
                            <img src={require("./assets/images/UI_Scanicon.png")} style={{ height: "25px", width: "25px", marginRight: "10px" }} /> <br /> and capture QR code</li>
                          </ol>
                        </div>

                        <div className="line-container">
                          <div className="line"></div>
                            <span className="text">OR</span>
                          <div className="line"></div>
                        </div>
                        <div className='d-flex justify-content-center mt-3'>
                          <span>
                            Open <span style={{ color: "#5AC994",fontWeight: "bold" }}>Bhutan NDI</span> Wallet <a href="https://kiwhp.app.link/?t=" target='_blank' style={{ color: "blue", textDecoration: 'underline' }}>here</a>
                          </span>
                        </div>
                        <div className='d-flex justify-content-center mt-4'>
                          <a href='https://www.youtube.com/@BhutanNDI' target='_blank' className='btn rounded-pill' style={{ backgroundColor: "#5AC994", color: '#fff' }}>Watch video guide <PlayCircle size={24} /></a>
                        </div>
                        <div className='d-flex justify-content-center mt-4'>
                            <span> Download Now! </span>
                        </div>
                        <div className='d-flex justify-content-center mt-2'>
                            <img src={require("./assets/images/Googleplay.png")} style={{ height: "35px", width: "125px", marginRight: "10px" }} />
                            <img src={require("./assets/images/Apple.png")} style={{ height: "35px", width: "125px", marginRight: "10px" }} />
                        </div>
                      </div>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}